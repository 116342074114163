<template>
    <a
        :class="['btn', props.variant, small ? 'small' : '', !props.enabled ? 'disabled' : '']"
        :href="effectiveHref" :target="props.target"
        :style="$attrs.style"
        @click="checkClickBlock"
    >
        <slot />
    </a>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<script setup>
import { defineProps, useAttrs, computed } from 'vue'

const attrs = useAttrs()
const props = defineProps({
    variant: { type: String, default: "info" },
    small: { type: Boolean },
    enabled: { type: Boolean, default: true },
    href: { type: String },
    target: { type: String, default: "" }
})

const effectiveHref = computed(() => props.enabled ? props.href : null);
const checkClickBlock = (event) => {
    if (!props.enabled) {
        event.preventDefault()
        event.stopPropagation()
        return;
    }

    // otherwise, let the click handler that might have been registered by our parent through
    attrs.onClick && attrs.onClick(event)
}
</script>

<style scoped>
.btn {
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 18px;
    border-radius: 3px;
    background-color: rgb(29, 59, 255);
    color: white;
    margin-right: 5px;

    white-space: nowrap;
}
.btn.small {
    padding: 3px 8px;
}

.success {
    background-color: rgb(64, 165, 118);
    color: white;
}
.info {
    background-color: rgb(29, 152, 223);
    color: white;
}
.warning {
    background-color: rgb(230, 194, 65);
    color: black;
}
.error {
    background-color: rgb(237, 67, 25);
    color: black;
}

.disabled {
    background-color: #ccc;
    color: white;
    cursor: default;
}
</style>
