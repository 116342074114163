<template>
    <div class="wide-bar">
        <div v-if="data == null">loading...</div>
        <div v-else-if="!username">
            <i>not logged in</i>
            <AppLinkButton style="margin-left: 10px;" small href="/api/saml2/login/">Log In</AppLinkButton>
        </div>
        <div v-else>
            logged in as <b>{{ username }}</b>

            <AppLinkButton style="margin-left: 10px;" small href="/api/saml2/logout/">Log Out</AppLinkButton>
        </div>
    </div>
</template>

<script setup>
import { API_BASE_URL } from '@/config';
import { ref, computed } from 'vue';

import AppLinkButton from './AppLinkButton.vue';

// fetch current-user from API
const data = ref(null);

const getUserInfo = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/current-user/`);
        data.value = await response.json();
    } catch (err) {
        data.value = null;
        console.error(err);
    }
};
getUserInfo();

const username = computed(() => data.value && data.value.username );
</script>

<style scoped>
.wide-bar {
    margin: 0;
    margin-bottom: 1em;
    padding: 8px;
    background-color: #3c4564;
    color: white;
    display: flex;
    justify-content: flex-end;
}
.wide-bar a {
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: #d3ebfe;
}
</style>