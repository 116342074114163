import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// toastify
import 'vue3-toastify/dist/index.css';

// font awesome setup
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faSquareCheck as faSquareCheckSolid,
    faCircleInfo as faCircleInfoSolid,
} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

library.add(faSquareCheckSolid)
library.add(faCircleInfoSolid)
library.add(faSquare)

// import our site css last so it can override others
import Vue3Toasity, { toast } from 'vue3-toastify';
import '@/css/main.css'

createApp(App)
    .use(router)
    .use(Vue3Toasity, {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
        theme: toast.THEME.COLORED
    })
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
