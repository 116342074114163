<template>
    <div :class="['note', props.variant, light ? 'light' : '']">
        <div class="icon-pane">
            <font-awesome-icon icon="fa-solid fa-circle-info" size="lg" />
        </div>
        <slot />
    </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    variant: {type: String, default: 'info'},
    light: {type: Boolean, default: false}
})
</script>

<style scoped>
.note {
    display: flex;
    align-items: center;
}
.icon-pane {
    flex: 0 1 30px;
}

.note {
    border-radius: 3px;
    padding: 10px;
}

.success { background-color: rgb(64, 165, 118); color: white; }
.info { background-color: rgb(29, 152, 223); color: white; }
.warning { background-color: rgb(230, 194, 65); color: black; }
.error { background-color: rgb(237, 67, 25); color: black; }

.success.light { background-color: rgb(191, 236, 215); color: black; }
.info.light { background-color: rgb(198, 234, 255); color: black; }
.warning.light { background-color: rgb(246, 234, 189); color: black; }
.error.light { background-color: rgb(249, 183, 167); color: black; }

</style>