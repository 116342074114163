<template>
    <div v-if="data == null"> loading...</div>
    <div v-else-if="!rec">
        <h1>No Records Found</h1>
        <p>No records were found for your account. This may be because you've
        never submitted a remote work authorization, or your submission hasn't
        been processed yet. If you'd like to submit one, you can use
        the following link:
        </p>

        <p>
            <a :href="REMOTE_WORK_FORM" target="_blank">
            Remote Work Participation Agreement
            </a>
        </p>
    </div>
    <div v-else>
        <h1 style="margin-top: 0;">Your submission from {{ rec.effective_date }}</h1>

        <h2>Remote Work Location</h2>
        <div class="vertical-table">
            <div class="tbl-row">
                <div>International?:</div>
                <div>{{ rec.is_the_employee_working_internationally ? 'yes' : 'no' }}</div>
            </div>
            <div v-if="rec.is_the_employee_working_internationally" class="tbl-row">
                <div>Country:</div>
                <div>{{ rec.remote_work_location_country }}</div>
            </div>
            <div class="tbl-row">
                <div>City:</div>
                <div>{{ rec.remote_work_location_city }}</div>
            </div>
            <div class="tbl-row">
                <div>State:</div>
                <div>{{ rec.remote_work_location_state }}</div>
            </div>
        </div>

        <h2>Remote Working Times</h2>

        <h3 style="margin-bottom: 0px;">Days</h3>
        <div class="horizontal-table">
            <div class="tbl-column" v-for="(working, day) in workingDays" :key="day">
                <p>{{ capitalize(day) }}</p>
                <p style="margin-left: 8px;">
                    <font-awesome-icon v-if="working" icon="fa-solid fa-square-check" />
                    <font-awesome-icon v-else icon="fa-regular fa-square" />
                </p>
            </div>
        </div>

        <h3>Hours</h3>
        <p><i>"{{  rec.remote_work_hours }}"</i></p>

        <hr style="background-color: #ccc; border: none; height: 1px;" />

        <h1>Next Steps</h1>

        <div v-if="rec.current_call">
            <p>
            <b>If the above information is still valid</b>, press "Approve My
            Information" to indicate to us that you've reviewed and approved of
            your record on file.
            </p>

            <p>
            <b>If the above information is no longer accurate</b>, please use the
            following linked form to submit an up-to-date remote work agreement,
            or contact us if you need further assistance.
            </p>

            <p>
            <i>(Note that after submitting the form linked below, it may take up
            to a month for the RWA displayed on this site to match your
            submission. If you do submit an RWA using the form linked below, you
            don't need to validate in this call; your RWA will be considered
            your approval.)</i>
            </p>

            <p><a :href="REMOTE_WORK_FORM" target="_blank">Submit a new Remote Work Participation Agreement</a></p>

        </div>
        <div v-else>
            <p>
                <b>There is currently no open call for validating remote work
                authorizations, so you can't approve the above data at this time.</b>
                If we do need you to validate your remote work authorization in the
                future, we'll contact you via email to come back to this site.
            </p>
        </div>

        <AppNote v-if="rec.last_approval" light>
            You last marked this as approved on {{ formatDateTime(rec.last_approval.agreed_on) }}
        </AppNote>

        <div class="action-tray">
            <AppLinkButton variant="success" :enabled="rec.current_call" @click="validateRecord">
            Approve My Information
            </AppLinkButton>
        </div>
    </div>
</template>

<script setup>
import { API_BASE_URL, REMOTE_WORK_FORM } from '@/config'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useCookies } from "vue3-cookies"

import { formatDateTime } from '@/support/formatting'

import AppNote from '@/components/AppNote.vue'
import AppLinkButton from '@/components/AppLinkButton.vue'

// bring in resources
const router = useRouter()
const { cookies } = useCookies()

// fetch workspecs from API

const data = ref(null)

const capitalize = (in_str) => `${in_str.charAt(0).toUpperCase()}${in_str.slice(1)}`

const getLatestRecord = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/workspecs/latest/`)

        // check if the response is a 403, i.e. they're not logged
        // in or don't have valid creds. redirect them to login if so
        if (response.status == 403) {
            router.push({ name: 'login' })
            return
        }
        else if (response.status == 404) {
            // no record exists for this user
            data.value = false
            return
        }

        const payload = await response.json();
        data.value = payload;
    }
    catch (err) {
        // this catches network errors
        data.value = null
        console.error(err)
    }
};

getLatestRecord();

// returns the latest work record if it's truthy
const rec = computed(() => data.value );

const workingDays = computed(() => {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return days.reduce((acc, x) => { acc[x] = rec.value[x]; return acc; }, {});
})

const validateRecord = async () => {
    if (!rec.value) {
        toast("Can't validate a non-existent record")
        return
    }

    try {
        const response = await fetch(`${API_BASE_URL}/workspecs/${rec.value.id}/validate/`, {
            method: 'POST',
            mode: 'same-origin',
            headers: {
                'X-CSRFToken': cookies.get('csrftoken')
            }
        })

        if (response.status == 403) {
            // a 403 would indicate here that they're not logged in, so
            // go have them do that and come back
            router.push({ name: 'login' })
            return
        }
        else if (response.status == 404) {
            // either no record exists for the user, or there's no open call
            // either way, the 'detail' field will describe the situation
            const err_data = await response.json();
            toast(`${err_data.detail}`, { type: 'error' });
            return
        }

        // if we reach this point, we succeeded
        toast(`Your remote work agreement has been marked as approved`, { type: 'success' });

        // refresh their record, too
        await getLatestRecord();
    }
    catch (err) {
        toast(`Error: ${err}`, { type: 'error' });
    }
}
</script>

<style scoped>
.action-tray {
    margin-top: 3em;
}

/* animation for blinking the form on an update */
.blink {
  animation: blink 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  opacity: 0;
}

@keyframes blink {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
</style>
