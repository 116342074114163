import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import AgreementView from '../views/AgreementView.vue'
import { DEFAULT_APP_TITLE } from '@/config'

const routes = [
  {
    path: '/',
    name: 'agreement',
    component: AgreementView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  { 
    path: '/:catchAll(.*)', 
    name: '404View',
    component: () => import(/* webpackChunkName: "404view" */ '../views/404View.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_APP_TITLE;
    });
});

export default router
